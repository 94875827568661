import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";

import ProgressStepper from "../../../../../components/ProgressStepper/ProgressStepper";
import { SelectDateRangeState } from "../../../../../components/SelectDateRange/SelectDateRange";
import { UserRoleName } from "../../../../graphql";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import SkillsReportBuilderStepOne from "./SkillsReportBuilderStepOne";
import SkillsReportBuilderStepTwo, {
  SkillsReportBuilderStepTwoFormData,
} from "./SkillsReportBuilderStepTwo";

type AnalyticsSkillsReportBuilderProps = {
  onGenerateReport: (data: any) => void;
  positionId?: string;
  onPositionIdChange: (positionId: string) => void;
  candidateFilter?: string;
  onCandidateFilterChange: (candidateFilter: string) => void;
  dateRange: SelectDateRangeState;
  onDateRangeChange: (dateRange: SelectDateRangeState) => void;
  builderStep: number;
  setBuilderStep: (step: number) => void;
};

const AnalyticsSkillsReportBuilder: React.FC<
  AnalyticsSkillsReportBuilderProps
> = ({
  onGenerateReport,
  positionId,
  onPositionIdChange: setPositionId,
  candidateFilter,
  onCandidateFilterChange: setCandidateFilter,
  dateRange,
  onDateRangeChange: setDateRange,
  builderStep,
  setBuilderStep,
}) => {
  const currentUser = useCurrentUser();

  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const generateReport = (data: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills } = data;
    onGenerateReport({
      positionId,
      candidateFilter,
      dateRange,
      source,
      skills: skills.filter((skill) => skill.name.trim() !== ""),
    });
    setBuilderStep(2);
  };

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      bg="rgba(35, 152, 251, 0.03)"
      pb="100px"
    >
      <Flex
        flexDir="row"
        backgroundColor="white"
        borderColor="gray.200"
        borderBottomWidth="1px"
        px="8"
        py="4.5"
      >
        <Text mr="2" fontWeight="500" color="gray.800" fontSize="xl">
          Skills Coverage
        </Text>
        <Tag
          fontSize="xs"
          bgColor="purple.300"
          color="white"
          px="2"
          borderRadius="28px"
        >
          BETA
        </Tag>
      </Flex>
      <Box py="8" margin="0 auto">
        <ProgressStepper
          steps={["Basics", "Skills", "Done"]}
          activeStepIndex={builderStep}
          stepBgColor="#F5F9FD"
        />
      </Box>
      {builderStep === 0 && (
        <SkillsReportBuilderStepOne
          onCancel={() =>
            setBuilderStep(1)
          } /* TODO: figure out what clicking cancel does on the first screen */
          onComplete={() => setBuilderStep(1)}
          positionId={positionId}
          candidateFilter={candidateFilter}
          dateRange={dateRange}
          onPositionIdChange={setPositionId}
          onCandidateFilterChange={setCandidateFilter}
          onDateRangeChange={setDateRange}
        />
      )}
      {builderStep === 1 && (
        <SkillsReportBuilderStepTwo
          onCancel={() => setBuilderStep(0)}
          onComplete={(data) => generateReport(data)}
        />
      )}
    </Flex>
  );
};

export default AnalyticsSkillsReportBuilder;
