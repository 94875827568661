import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";

import { BetaTag, LoadingIndicator } from "../../../../components";
import { SelectDateRangeState } from "../../../../components/SelectDateRange/SelectDateRange";
import { formatDateRange } from "../../../../utils/datetime";
import { AnalyticsSkillsReportQuery, UserRoleName } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import AnalyticsSkillsReportTable from "./AnalyticsSkillsReportTable";

type AnalyticsSkillsReportResultsProps = {
  data: AnalyticsSkillsReportQuery | undefined;
  loading: boolean;
  startBuildReport: () => void;
  dateRange: SelectDateRangeState;
};

const AnalyticsSkillsReportResults: React.FC<
  AnalyticsSkillsReportResultsProps
> = ({ data, loading, startBuildReport, dateRange }) => {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const reportCandidates = data?.skillsReport?.candidates || [];
  const skillNamesList = data?.skillsReport?.skillNames || [];

  const tableData = reportCandidates.map((candidate) => {
    const skills = skillNamesList.map((skillName) => {
      const skill = candidate.skills.find((s) => s.name === skillName);
      return {
        name: skillName,
        covered: skill?.covered || false,
      };
    });

    return {
      id: candidate.candidateId,
      candidateName: candidate.candidateName,
      numInterviews: candidate.numInterviews,
      skills,
    };
  });

  const candidateCount = reportCandidates.length;
  const totalInterviewCount = reportCandidates.reduce(
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    (acc, candidate) => acc + candidate.numInterviews,
    0
  );
  const positionDisplayTitle =
    data?.skillsReport?.positionDisplayTitle || "No position title";
  const subHeader = loading
    ? " "
    : `${candidateCount} candidates • ${totalInterviewCount} interviews • ${positionDisplayTitle}`;

  const chartCounts: AnalyticsBarChartData = skillNamesList
    .map((skillName, i) => {
      const count: number = reportCandidates.filter((candidate) =>
        candidate.skills.find((s) => s.name === skillName && s.covered)
      ).length;

      return {
        key: `skill-${i}-${skillName}`,
        label: skillName,
        value: (count / candidateCount) * 100,
      };
    })
    .sort((a, b) => b.value - a.value);

  return (
    <Box pb="100px">
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        h={16}
        px={8}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <HStack spacing={2} align="baseline">
          <Text fontSize="xl" fontWeight="500" color="gray.800">
            Skills Coverage
          </Text>
          <BetaTag bg="purple.300" _hover={{ bg: "purple.400" }} />
          <Button
            variant="link"
            fontSize="sm"
            fontWeight={500}
            // onClick={() => {}}
          >
            Details
          </Button>
        </HStack>
        <Button colorScheme="blue" size="sm" onClick={startBuildReport}>
          New report
        </Button>
      </Flex>
      <Flex
        flexDir="row"
        px={8}
        py={4}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Text as="span" fontWeight="500">
          {subHeader}
        </Text>
      </Flex>
      <Flex flexDir="column" p={8} pt={12}>
        <VStack spacing={6}>
          <Flex direction="column" alignSelf="start">
            <Text fontSize="3xl" fontWeight={400}>
              How consistently are key interview topics being covered?
            </Text>
            <Text fontWeight={400} color="gray.400">
              Identify which topics are discussed during interviews across all
              candidates for one or more positions.
            </Text>
          </Flex>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box w="100%" py="6">
              {loading && <LoadingIndicator />}
              {!loading && tableData.length === 0 && (
                <Center>
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!loading && tableData.length > 0 && (
                <AnalyticsBarChart
                  data={chartCounts}
                  margin={{ left: 180 }}
                  multiColor
                />
              )}
            </Box>
          </ReportSection>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box mt="3" width="100%">
              {loading && <AnalyticsReportTableSkeleton />}
              {!loading && tableData.length === 0 && (
                <Center py="6">
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!loading && tableData.length > 0 && (
                <AnalyticsSkillsReportTable
                  // currentTopic={queryConfig.topic.value}
                  tableData={tableData}
                  skills={skillNamesList}
                />
              )}
            </Box>
          </ReportSection>
        </VStack>
      </Flex>
    </Box>
  );
};

const ReportSection: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <VStack
      spacing={6}
      w="100%"
      p={6}
      pt={5}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="xl"
    >
      {children}
    </VStack>
  );
};

const ReportSectionHeader: React.FC<{
  header: string;
  date: string;
  subheader: string;
}> = ({ header, date, subheader }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%" direction="row" align="center" justify="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {header}
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.400" ml={2}>
          {date}
        </Text>
      </Flex>
      <Text fontSize="sm" fontWeight={400} color="gray.600">
        {subheader}
      </Text>
    </Flex>
  );
};

export default AnalyticsSkillsReportResults;
